.section__padding{
    padding: 4rem 4rem;
}
.footer{
    background-color: #fff;
}
.sb__footer{
    display: flex;
    flex-direction: column;
}
.sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
}

.sb__footer-links-div{
    width: 400px;
    margin: 1rem;
    display: block;
    color: #000;
}
a{
    color: #777;
    text-decoration: none;
    font-size: 16px;
}
a:hover{
    color: #000;
}
.socialmedia{
    display: flex;
    flex-direction: row;
}
 
.socialmedia{
    gap: 20px;
}

.socialmedia p{
    width: 80%;
    font-size: 16px;
    padding: 20px;
    color: #913e0c;
}

.socialmedia p:hover{
    background-color: #f26f1e;
    color: #fff;
    border-radius: 40px;
}

.sb__footer-links-div h4{
    font-size: 20px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb__footer-links-div p{
    font-size: 15px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
    text-align: left;
}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p {
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: #000;
    font-weight: 600;
}

hr{
    color: #000;
    width: 100%;
}

.sb_footer-copyright{
    font-size: 13px;
    line-height: 15px;
    color: #777;
    font-weight: 600;
}

@media screen and (max-width: 550px){
    .sb__footer-links div{
        margin: 1rem 0;
    }
    .sb_footer-below {
        flex-direction: column;
        justify-content: left;
    }
    .sb_footer-below-links{
        flex-direction: column;
    }
    .sb_footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}