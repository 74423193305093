.contact-us-section {
    max-width: 900px;
    margin: auto;
    margin-top: 2rem;
}

.contact-us-title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Gilroy-Bold';
}

.contact-us-description,
span {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
    color: #b1b1b1;
    text-align: center;
    font-family: 'Gilroy-Medium';
}

.contact-us-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(338px, auto));
    grid-row-gap: 1rem;
    grid-column-gap: 0rem;
    margin: 2rem auto;
}

.contact-handles {
    max-width: 300px;
    margin: auto;
}

.contact-form {
    max-width: 400px;
    margin: auto;
}

.contact-form-headers {
    font-weight: 700;
    font-size: 20px;
    font-family: 'Gilroy-Bold';
}

.header-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-label {
    margin-bottom: 0.5rem;
    color: #858887;
    font-family: 'Gilroy-Bold';
    font-size: 1rem;
}

.form-label-subject {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #858887;
    font-family: 'Gilroy-Bold';
}

::placeholder {
    color: #858887;
    font-size: 12px;
    font-family: 'Gilroy-Regular';
}

.contact-button {
    text-align: left;
}

.button-send {
    border: none;
    background-color: #f26f1e;
    color: #ffff;
    font-size: 1rem;
    font-family: 'Gilroy-Bold';
    width: 108px;
    height: 34px;
    border-radius: 7px;
}

.contact-us-section-heading {
    margin-top: -10rem;
}



.contact-form-field {
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
}

.form-control-subject,
textarea {
    width: 94vw !important;
}

@media only screen and (min-width:600px) {
    .form-control-subject {
        width: 400px !important;
    }

    .form-control-message {
        width: 400px !important;
    }
}