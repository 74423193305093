.section-heading {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(-45deg, #f26f1e, #F24E1E);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Gilroy-Bold';
    margin-top: 10rem;
}

.services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 30px;
    justify-content: center;
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
}

.service:last-child {
    justify-content: center;
}

.service h3 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: 'Gilroy-Bold';
}

.service p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
    color: #b1b1b1;
    text-align: "center";
    font-family: 'Gilroy-Medium';
}

.wrap-icon {
    display: inline-block;
    height: 64px;
    width: 64px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    line-height: 64px;
}

.system-icon {
    font-size: 3rem;
    color: #f26f1e;
}

@media only screen and (max-width: 500px) {
    .section-heading {
        margin-top: 30%;
    }
}