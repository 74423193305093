.hero-section{
    background: linear-gradient(to right, #913e0c 0%, #F24E1E), url("../../assets/images/hero-bg-2.jpg") no-repeat center center/cover; 
    background-blend-mode: multiply; 
    position: relative;
    height: 100vh;
    margin-bottom: 10%;
    z-index: -99;
}
.hero-section .wave {
    bottom: -180px;
}
.hero-section .wave {
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    bottom: -150px;
}
.hero-section > .container > .row {
    height: 100vh;
    min-height: 880px;
}

.cont{
    margin-top: -60%;
    z-index: 99;
}

.align-items-center {
    align-items: center!important;
}
.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}

.left{
    width: 50%;
    padding-top: 15%;
}

.right{
    width: 50%;
}

.right img{
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.h1{
    color: #1ef269;
    font-size: 56px;
    line-height: 67.2px;
}

.p{
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
}

.btn-white{
    font-size: 18px;
    line-height: 27px;
    color: #913e0c;
    background-color: #fff;
    padding: 10px 30px;
}

.btn-white:hover{
    background-color: #f26f1e;
    color: #fff;
}

@media only screen and (max-width: 500px) {
    .cont{
        margin-top: -200%;
        z-index: 99;
    }

    .hero-section .wave {
        bottom: -180px;
    }
    .hero-section .wave {
        width: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        bottom: -150px;
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        display: block;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
    
    .left{
        width: 100%;
        /* padding-top: 15%; */
    }
    
    .right{
        width: 100%;
    }
    
    .right img{
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }

    .h1{
        color: #1ef269;
        font-size: 26px;
        line-height: 37.2px;
        padding: 0 4%;
    }
   
    .p{
        color: #fff;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        font-weight: 600;
        padding: 0 7%;
    }
    .right img{
        width: 80%;
        height: 30vh;
        object-fit: contain;
    }
}