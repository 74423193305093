body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./assets/fonts/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Black";
  src: local("Gilroy-Black"),
    url(./assets/fonts/Gilroy-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./assets/fonts/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url(./assets/fonts/Gilroy-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(./assets/fonts/Gilroy-Medium.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: #913e0c;
  --secondaryColor: orange;
  --textColor: #fff;
  --inactiveColor: #9fa1a4;
}

header {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: 	rgb(150,75,0, .8);
  color: var(--textColor);
  z-index: 999;
}

.logo{
  font-size: 28px;
  line-height: 28px;
  font-family: Poppins, sans-serif;
}

nav a {
  margin: 0 2rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
}

nav a:active{
  color:  var(--inactiveColor);
}

nav a:hover {
  color:  var(--inactiveColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 99;
  }

  .logo{
    font-size: 18px;
    line-height: 18px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 550px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  .logo{
    font-size: 18px;
    line-height: 18px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}